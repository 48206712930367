import React from 'react'
import { Box, Typography } from '@mui/material'

const Title = ({text, colour}) => {
  return (
    <Typography 
      variant='h4'
      fontFamily='Poppins'
      m="7%"
      mt="22.5%"
      mb="10%"
      sx={{ 
        color: {colour},
        }}
      >
        {text}
    </Typography>
  )
}

const Body = ({text, colour}) => {
  return (
    <Typography
      variant='body2'
      fontFamily='Poppins'
      m="10%"
      mt="0"
      mb="2%"
      sx={{ 
        color: {colour},
      }}
    >
      {text}
    </Typography>
  )
}

const Page = ({titleText, bodyText, titleTextColour, bodyTextColour, imageUrl, bgColourDeep, bgColourMid, bgColourLight}) => {
  return (
    <Box
      m="0"
      height="100vh"
      display="flex"
      position="relative"
      alignItems="center"
      justifyContent="center"
    >
      <Box
        m="auto"
        width="60%"
        height="60%"
        display="grid"
        borderRadius="4px"
        gridTemplateColumns="2fr 1.5fr"
        sx={{ 
          backgroundImage:`linear-gradient(to left, ${bgColourDeep}, ${bgColourMid}, ${bgColourLight})`,
          borderRadius:"10px"
         }}
      >
        <Box
          width="100%"
          display="flex"
          alignItems="center"
          flexDirection="column"
        >
          <Title 
            text={titleText}
            colour={titleTextColour}
          />
          <Body 
            text={bodyText}
            colour={bodyTextColour}
          />
        </Box>
        <Box
          width="100%"
          height="100%"
        />
        <img
          alt="picture"
          width="20%"
          height="30%"
          src={imageUrl}
          style={{ 
            cursor: "pointer", 
            position: "absolute",
            top: "32.5%",
            right: "27.5%"
          }}
        />
      </Box>  
    </Box>
  )
}

export default Page