import React from "react";
import Welcome from "./pages/Welcome";
import Page from "./pages/Page";
import PageAlt from "./pages/PageAlt";
import Header from "./pages/Header";
import Footer from "./pages/Footer";
import {
  pageOne,
  pageTwo,
  pageThree,
  pageFour,
  pageFive,
  pageSix,
} from "./data/data";

const App = () => {
  return (
    <div className="app">
      <Header />
      <Welcome />
      <Page
        titleText={pageOne.titleText}
        bodyText={pageOne.bodyText}
        titleTextColour={pageOne.titleTextColour}
        bodyTextColour={pageOne.bodyTextColour}
        imageUrl={pageOne.imageUrl}
        bgColourDeep={pageOne.bgColourDeep}
        bgColourMid={pageOne.bgColourMid}
        bgColourLight={pageOne.bgColourLight}
      />
      <PageAlt
        titleText={pageTwo.titleText}
        bodyText={pageTwo.bodyText}
        titleTextColour={pageTwo.titleTextColour}
        bodyTextColour={pageTwo.bodyTextColour}
        imageUrl={pageTwo.imageUrl}
        bgColourDeep={pageTwo.bgColourDeep}
        bgColourMid={pageTwo.bgColourMid}
        bgColourLight={pageTwo.bgColourLight}
      />
      <Page
        titleText={pageThree.titleText}
        bodyText={pageThree.bodyText}
        titleTextColour={pageThree.titleTextColour}
        bodyTextColour={pageThree.bodyTextColour}
        imageUrl={pageThree.imageUrl}
        bgColourDeep={pageThree.bgColourDeep}
        bgColourMid={pageThree.bgColourMid}
        bgColourLight={pageThree.bgColourLight}
      />
      <PageAlt
        titleText={pageFour.titleText}
        bodyText={pageFour.bodyText}
        titleTextColour={pageFour.titleTextColour}
        bodyTextColour={pageFour.bodyTextColour}
        imageUrl={pageFour.imageUrl}
        bgColourDeep={pageFour.bgColourDeep}
        bgColourMid={pageFour.bgColourMid}
        bgColourLight={pageFour.bgColourLight}
      />
      <Page
        titleText={pageFive.titleText}
        bodyText={pageFive.bodyText}
        titleTextColour={pageFive.titleTextColour}
        bodyTextColour={pageFive.bodyTextColour}
        imageUrl={pageFive.imageUrl}
        bgColourDeep={pageFive.bgColourDeep}
        bgColourMid={pageFive.bgColourMid}
        bgColourLight={pageFive.bgColourLight}
      />
      <PageAlt
        titleText={pageSix.titleText}
        bodyText={pageSix.bodyText}
        titleTextColour={pageSix.titleTextColour}
        bodyTextColour={pageSix.bodyTextColour}
        imageUrl={pageSix.imageUrl}
        bgColourDeep={pageSix.bgColourDeep}
        bgColourMid={pageSix.bgColourMid}
        bgColourLight={pageSix.bgColourLight}
      />
      <Footer />
    </div>
  );
};

export default App;
