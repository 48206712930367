import React from 'react'
import { Box, Typography } from '@mui/material'
import { title, body, body2, body3 } from '../data/data'

const titleTextColour = "black"
const bodyTextColour = "black"

const url = "../images/background3.png"

const Title = ({text, colour}) => {
  return (
    <Typography 
      variant='h4'
      fontFamily='Poppins'
      m="7%"
      mt="12.5%"
      mb="5%"
      sx={{ 
        color: {colour},
        }}
      >
        {text}
    </Typography>
  )
}

const Body = ({text, colour}) => {
  return (
    <Typography
      variant='body2'
      fontFamily='Poppins'
      m="7%"
      mt="0"
      mb="2%"
      sx={{ 
        color: {colour},
      }}
    >
      {text}
    </Typography>
  )
}

const Welcome = () => {
  return (
    <Box
      m="0"
      height="100vh"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Box
        m="auto"
        width="70%"
        height="70%"
        display="flex"
        alignItems="center"
        borderRadius="4px"
        flexDirection="column"
        sx={{ 
          backgroundImage:`linear-gradient(to bottom, rgba(255, 255, 255, 1), rgba(0, 0, 0, 0.5)),url(${url})`,
          backgroundPosition:"center",
          backgroundRepeat:"no-repeat",
          backgroundSize:"cover"
         }}
      >
        <Title 
          text={title}
          color={titleTextColour}
        />
        <Body
          text={body}
          color={bodyTextColour}
        />
        <Body
          text={body2}
          color={bodyTextColour}
        />
        <Body
          text={body3}
          color={bodyTextColour}
        />
      </Box>  
    </Box>
  )
}

export default Welcome