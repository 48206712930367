export const title = "Empowering Visions, Navigating Success";
export const body =
  "At 6334 Group, we are your partners in progress, providing comprehensive solutions to turn your visions into reality. With a team of dedicated experts across various industries, we are your trusted advisors, helping you navigate the complexities of business and achieve sustainable success.";
export const body2 =
  "Our all-purpose consultancy firm is built on a foundation of expertise, innovation, and client-centric approach. From startups to established enterprises, we offer tailored strategies and insights that drive growth, optimize operations, and maximize potential. Whether you're seeking financial guidance, strategic planning, market insights, or technology integration, we are committed to understanding your unique challenges and delivering actionable solutions.";
export const body3 =
  "With a deep understanding of the ever-evolving business landscape, our consultancy services are designed to equip you with the tools and knowledge needed to thrive in a dynamic world. Let us be your compass as you navigate towards your goals, and together, we'll empower your journey to excellence. Discover the transformative power of collaboration with 6334.";

export const pageOne = {
  titleText: "Facilitation and Training",
  bodyText:
    "Enhance skills and knowledge with our Facilitation and Training programs. We offer customized workshops and training sessions that empower your team to excel. Our experienced facilitators guide your workforce towards personal and professional development.",
  titleTextColour: "black",
  bodyTextColour: "black",
  imageUrl: "../images/training.png",
  bgColourDeep: "rgba(3, 66, 66, 1)",
  bgColourMid: "rgba(0, 128, 128, .5)",
  bgColourLight: "rgba(255, 255, 255, 0.25)",
};

export const pageTwo = {
  titleText: "Business and Project Mgt",
  bodyText:
    "Drive success with effective Business and Project Management. Our seasoned professionals oversee every aspect, from conceptualization to execution. With proven methodologies, we ensure your projects are delivered on time, within budget, and aligned with your objectives.",
  titleTextColour: "black",
  bodyTextColour: "black",
  imageUrl: "../images/project.png",
  bgColourDeep: "rgba(8, 35, 77, 1)",
  bgColourMid: "rgba(15, 82, 186, .5)",
  bgColourLight: "rgba(255, 255, 255, 0.25)",
};

export const pageThree = {
  titleText: "Data and IT",
  bodyText:
    "Harness the power of data and technology with our Data and IT solutions. From data analytics to system integration, we help you leverage insights for strategic decision-making and enhanced efficiency. Our experts ensure your IT infrastructure supports innovation, giving you a competitive edge in the digital age.",
  titleTextColour: "black",
  bodyTextColour: "black",
  imageUrl: "../images/data.png",
  bgColourDeep: "rgba(66, 66, 66, 1)",
  bgColourMid: "rgba(192, 192, 192, .5)",
  bgColourLight: "rgba(255, 255, 255, 0.25)",
};

export const pageFour = {
  titleText: "Logistics and Field",
  bodyText:
    "Streamline operations and optimize logistics with our Logistics and Field solutions. We create agile strategies that minimize costs and enhance supply chain performance. Our comprehensive approach ensures seamless coordination between your field operations, suppliers, and customers.",
  titleTextColour: "black",
  bodyTextColour: "black",
  imageUrl: "../images/logistics.png",
  bgColourDeep: "rgba(46, 22, 5, 1)",
  bgColourMid: "rgba(139, 69, 19, .5)",
  bgColourLight: "rgba(255, 255, 255, 0.25)",
};

export const pageFive = {
  titleText: "Portfolio Assessment",
  bodyText:
    " Gain actionable insights and drive growth through our Portfolio Assessment services. We conduct comprehensive evaluations to identify strengths, weaknesses, and opportunities within your portfolio. Our expert analysis provides a clear roadmap for strategic enhancements and sustainable success.",
  titleTextColour: "black",
  bodyTextColour: "black",
  imageUrl: "../images/portfolio.png",
  bgColourDeep: "rgba(25, 3, 41, 1)",
  bgColourMid: "rgba(106, 13, 173, .5)",
  bgColourLight: "rgba(255, 255, 255, 0.25)",
};

export const pageSix = {
  titleText: "Financial Modelling and Advisory",
  bodyText:
    "Achieve financial excellence with our Financial Modelling and Advisory services. We create robust financial models that guide strategic decisions and help mitigate risks. Our seasoned advisors offer insights that empower your financial planning and ensure sustainable growth.",
  titleTextColour: "black",
  bodyTextColour: "black",
  imageUrl: "../images/finance.png",
  bgColourDeep: "rgba(54, 45, 0, 1)",
  bgColourMid: "rgba(255, 215, 0, .5)",
  bgColourLight: "rgba(255, 255, 255, 0.25)",
};
