import React, {useState} from "react";
import { Box } from "@mui/material";
import "react-pro-sidebar/dist/css/styles.css";
import AlternateEmailOutlinedIcon from '@mui/icons-material/AlternateEmailOutlined';
import ContactFormModal from "../modals/ContactFormModal";

const ContactButton = () => {
    const [openModal, setOpenModal] = useState(false);
    return (
            <Box
                mr="2.5%"
                display="flex"
                alignItems="center"
                justifyContent="center"
                color="rgb(64, 24, 99)"
                onClick={() => {
                    setOpenModal(true);
                  }}
                sx={{
                    "&:hover": {
                        color:"rgb(139, 39, 226)",
                        translate:"scale(1.1)"
                    }
                  }}
            >
                <AlternateEmailOutlinedIcon />
                <ContactFormModal open={openModal} onClose={() => setOpenModal(false)}/>
            </Box>
    )
}

export default ContactButton