import React from "react";
import { Box } from "@mui/material";

const Header = () => {
    return (
        <Box
        width="100%"
        height="7.5%"
        display="flex"
        alignItems="center"
        justifyContent="center"
        backgroundColor="rgba(255, 255, 255, .25)"
        position="fixed"
        top="0"
        sx={{ 
            backdropFilter: "blur(10px)",
            cursor: "pointer"
         }}
        >
            <span className="eicon-companylogo"/>
        </Box>
    )
}

export default Header