import React, { useState } from "react";
import { Box, IconButton } from "@mui/material";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
// import { handleInputChange, handleSubmit } from "../functions";

const services = [
    "Facilitation and Training",
    "Data and IT",
    "Logistics and Field",
    "Assessment Portfolio",
    "Business and Project Management",
    "Financial Modelling and Advisory",
  
];

const ContactFormModal = ({ open, onClose }) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    services: {},
    description: "",
  });

  if (!open) return null;
  return (
    <>
        <Box
            backgroundColor="rgba(189, 189, 189, 0.9)"
            position="fixed"
            width="45%"
            height="80vh"
            top="10vh"
            right="5%"
            borderRadius="4px"
            onClick={(e) => {
                e.stopPropagation();
            }}
            sx={{ 
                zIndex: 2
             }}
        >
            <IconButton
                onClick={onClose}
                sx={{ 
                color: "black",
                zIndex: "3",
                position:"absolute",
                alignItems:"flex-end",
                right:"5%",
                top:"5%",
                "&:hover": {
                    color:'red'
                }
                }}
            >
                <CloseOutlinedIcon />
            </IconButton>
        </Box>
    </>
  );
};

export default ContactFormModal;
