import React from "react";
import { Box } from "@mui/material";

const FooterText = () => {
    return (
            <Box
                ml="2.5%"
            >
                <p>
                6334 Group of Companies<br />
                1, Oyekan Street, Yaba, Lagos. | +234 800 000 0000 |
                admin@6334group.net<br />&copy; 2023 6334 Business Services
                </p>
            </Box>
    )
}

export default FooterText