import React from "react";
import { Box } from "@mui/material";
import FooterText from "../components/FooterText";
import ContactButton from "../components/ContactButton";

const Footer = () => {
    return (
        <Box
            fontSize="x-small"
            width="100%"
            height="10%"
            justifyContent="space-between"
            display="flex"
            position="fixed"
            bottom="0"
            sx={{ 
            cursor: "pointer"
         }}
        >
            <FooterText />
            <ContactButton/>
        </Box>
    )
}

export default Footer